import React from 'react';
import ProjectLoopLogo from "../../../../images/pl_small_logo.png";
import "./styles.scss";

export default function ParallaxSection() {
    return (
        <div className="Industry-export-section">
            <div className="export-section-content">
                <div className="home-container sl-parallax-container">
                    <div className="export-details">
                        <img src={ProjectLoopLogo} className="logo" />
                        <h2>Designed & Developed By <br />Industry Experts</h2>
                        <p>
                        Crafted by a dedicated team of safety experts boasting more than 100+ years of combined project management expertise, we discerned a vital requirement for a project management software created by project managers, for project managers. We listened to the industry, and we've turned it into a reality.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
