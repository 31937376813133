import React from 'react'
import { Button } from 'antd'

export default function DemoSection({ mergeTop = true }) {
    return (
        <div className="Product-Page">
            <div className="personal-demo-section" style={{ padding: "0 40px" }} >
                <div className="home-container" style={{
                    maxWidth: "1050px",
                    margin: `${mergeTop ? "-300px" : "230px"} auto 230px`
                }}>
                    <div className="personal-demo-content feature-page-demo-section" style={{
                        background: "linear-gradient(130deg, rgb(230 125 66), rgb(252 133 67))",
                        padding: "100px 50px",
                        borderRadius: "20px"
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "column", alignItems: "center"
                        }}>
                            <h3 style={{
                                color: "white",
                                textTransform: "none",
                                marginBottom: "40px",
                                fontSize: "46px",
                                lineHeight: "1.2em",
                            }}>
                                <b>Experience ProjectLoop in Action</b>
                            </h3>
                            <a href="#" style={{
                                display: "inline-block",
                                marginBottom: "50px"
                            }}>
                                <Button size='large' shape="round" className="home-demo-btn">Request Demo</Button>
                            </a>
                            <p style={{
                                color: "white"
                            }}>
                                Request a demo today and discover the power of streamlined project management.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
